import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import OrderedList from "../components/orderedList"
import FeatureProjects from "../components/featureProjects"
import NextPage from "../components/nextPage"
import ScrollingBanner from "../components/scrollingBanner"
import SEO from "../components/seo"
import { graphql } from "gatsby"


class DevelopmentsPage extends React.Component {
	render() {
		var data = this.props.data.allDatoCmsOurDevelopment.edges[0].node;
		var projects = this.props.data.feature.edges;
		var all = this.props.data.all.edges;

		return (
			<Layout>
				<SEO title="Developments at Siera" />
				<Header page='Developments' className='is-dark' />
				<ScrollingBanner mobile='true' videoClass='filter-darken' video={data.videoUrl} title='Our Developments' />
				<div className='m-bg-white'>
					<div className="spacer m-hide"></div>
					<div className="spacer m-hide"></div>
					<FeatureProjects left={data.leftProject} right={data.rightProject} projects={projects} hide='hide' />
					<div className="spacer"></div>
					<div className="spacer"></div>
					<OrderedList secondRowName='Location' thirdRowName='Info' items={all} />
					<div className="spacer"></div>
				</div>
				<NextPage title='Our Story' link='/story' image={data.heroImage} />
			</Layout>
		)
	}
}

export default DevelopmentsPage

export const query = graphql`
  query Developments {
    allDatoCmsOurDevelopment {
	    edges {
	      node {
			leftProject{
				title
				featureImage {
				gatsbyImageData
				}
	        	location
			}
			rightProject{
				title
				featureImage {
				gatsbyImageData
				}
	        	location
			}
			id
	       videoUrl
		   heroImage {
			   gatsbyImageData(placeholder: DOMINANT_COLOR)
		   }
	      }
	    }
		}
		feature:allDatoCmsProject(filter: {featureProject: {eq: true}},sort: {fields: date, order: DESC}) {
	    edges {
	      node {
	        title
	        featureImage {
	          gatsbyImageData
	        }
	        location
			date
			id
	      }
	    }
	  }
	  
	  all:allDatoCmsProject(sort: {fields: date, order: DESC}) {
	    edges {
	      node {
	        title
	        featureImage {
	          gatsbyImageData
	        }
	        location
	        info
					id
					date(formatString: "yy")
	      }
	    }
	  }
  }
`
